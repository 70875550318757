import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-success-order-modal',
  templateUrl: './success-order-modal.component.html',
  styleUrls: ['./success-order-modal.component.scss'],
  standalone: true,
  imports: [],
})
export class SuccessOrderModalComponent extends LocalizedComponent {
  orderId = this.data;

  constructor(
    public dialogRef: MatDialogRef<SuccessOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    super();
  }

  close(): void {
    this.dialogRef.close(true);
  }

  navigate(): void {
    this.dialogRef.close(false);
    this.router.navigate([`/orders/${this.orderId}`]);
  }
}
