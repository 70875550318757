import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-quantity-discount-dialog',
  templateUrl: './quantity-discount-dialog.component.html',
  styleUrls: ['./quantity-discount-dialog.component.scss'],
  standalone: true,
  imports: [],
})
export class QuantityDiscountDialogComponent extends LocalizedComponent {
  constructor(public dialogRef: MatDialogRef<QuantityDiscountDialogComponent>) {
    super();
  }

  assetsPath = 'assets/img/';

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
