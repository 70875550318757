import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { provideRouter } from '@angular/router';
import { BulkOrderVideoComponent } from './bulk-order-video/bulk-order-video.component';
import { BulkOrdersErrorsComponent } from './bulk-orders-errors/bulk-orders-errors.component';
import { BulkOrdersComponent } from './bulk-orders/bulk-orders.component';
import { CartBulkOrdersActionsComponent } from './cart-bulk-orders-actions/cart-bulk-orders-actions.component';
import { CartProductAttributesComponent } from './cart-product-attributes/cart-product-attributes.component';
import { CartProductsTableComponent } from './cart-products-table/cart-products-table.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { CartComponent } from './cart.component';
import { ConfirmDeleteItemComponent } from './confirm-delete-item/confirm-delete-item.component';
import { QuantityDiscountBannerComponent } from './quantity-discount-banner/quantity-discount-banner.component';
import { QuantityDiscountDialogComponent } from './quantity-discount-dialog/quantity-discount-dialog.component';
import { SecondProductDiscountBannerComponent } from './second-product-discount-banner/second-product-discount-banner.component';
import { SuccessOrderModalComponent } from './success-order-modal/success-order-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    CartComponent,
    SuccessOrderModalComponent,
    ConfirmDeleteItemComponent,
    BulkOrdersErrorsComponent,
    BulkOrderVideoComponent,
    CartProductsTableComponent,
    SecondProductDiscountBannerComponent,
    CartProductAttributesComponent,
    CartBulkOrdersActionsComponent,
    CartSummaryComponent,
    BulkOrdersComponent,
    QuantityDiscountBannerComponent,
    QuantityDiscountDialogComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: CartComponent,
      },
    ]),
  ],
})
export class CartModule {}
