<div
  class="second-product-discount"
  *ngIf="shouldShowDiscountAsSecondProduct && shouldShowDiscountAsSecondProductBanner"
>
  <p
    class="second-product-discount__text body1--bold"
    *ngIf="!discountAsSecondProductApplied; else secondProductDiscountAppliedText"
  >
    {{ trans('CART.SECOND_PRODUCT_DISCOUNT.NOT_APPLIED_TEXT') }}
  </p>
  <ng-template #secondProductDiscountAppliedText>
    <i class="icon icon-cart-icons-Done second-product-discount__completion-check"></i>
    <p class="second-product-discount__text body1--bold">
      {{ trans('CART.SECOND_PRODUCT_DISCOUNT.APPLIED_TEXT') }}
    </p>
  </ng-template>
  <a
    class="second-product-discount__button body1--bold"
    [routerLink]="discountedProductsListPath.routerLink"
    [queryParams]="discountedProductsListPath.queryParams"
  >
    {{ trans('CART.SECOND_PRODUCT_DISCOUNT.SHOW_PRODUCTS') }}
  </a>
</div>
