import { NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-orders-errors',
  templateUrl: './bulk-orders-errors.component.html',
  styleUrls: ['./bulk-orders-errors.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf],
})
export class BulkOrdersErrorsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { errorMessages: { row: string; error: string; label: string }[] },
  ) {}
}
