<loader size="lg" [loading]="loading"></loader>

<div class="form" *ngIf="!loading">
  <div class="d-flex justify-content-between title">
    <div (click)="backToCart()" class="arrow-back">
      <img *ngIf="isLTR" loading="lazy" src="../../../assets/img/left-chevron.svg" />
      <img *ngIf="!isLTR" loading="lazy" src="../../../assets/img/right-chevron.svg" />
      <span>{{ trans('CART.TITLE') }}</span>
    </div>
    <button
      *ngIf="!isSubmitted"
      class="btn mat-raised-button place-order"
      (click)="orderNow()"
      data-test-id="confirm-bulk-upload-order-btn"
    >
      {{ trans('CART.BULK_UPLOAD_CONFIRM') }}
    </button>
  </div>
  <div class="preference-upsellling-container">
    <div class="preference-container" *ngIf="shouldShowUpOrderPreference">
      <app-order-preference
        (orderPreferenceProperties)="orderPreferencesChanged($event)"
        [orderPreferenceSource]="'bulk-orders-page'"
      ></app-order-preference>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="row">
      <div class="col col-12">
        <ng-container
          *ngIf="
            failedOrders?.length <= 0 && successfulOrders?.length <= 0;
            else showSuccessfulAndFailedOrders
          "
        >
          <app-order-overview
            *ngFor="let order of ordersToSubmit"
            [order]="order"
            [draft]="true"
          ></app-order-overview>
        </ng-container>
        <ng-template #showSuccessfulAndFailedOrders>
          <p *ngIf="failedOrders.length > 0" class="error-notice__text heading2--bold">
            حدث خطأ. يمكنك تحميل ملف بالطلبات التي حدث بها خطأ
            <button (click)="downloadRetryOrders()" class="error-notice__button heading2--bold">
              من هنا
            </button>
            .
          </p>
          <app-order-overview
            *ngFor="let order of failedOrders"
            [order]="order"
            [draft]="true"
          ></app-order-overview>

          <p *ngIf="successfulOrders.length > 0" class="success-message__text heading2--bold">
            الطلبات التي تمت بنجاح
          </p>
          <app-order-overview
            *ngFor="let order of successfulOrders"
            [order]="order"
            [draft]="true"
          ></app-order-overview>
        </ng-template>
      </div>
    </div>
  </div>
</div>
