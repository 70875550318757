<div class="product-attributes">
  <ng-container *ngIf="productColorName">
    <span class="color-variant">
      <span class="color-variant__color-name caption2--regular">{{ trans('CART.COLOR') }}:</span>
      <span
        [ngStyle]="{ backgroundColor: productColorHex }"
        class="color-variant__color-ball"
      ></span>
    </span>
  </ng-container>
  <span *ngIf="productSize" class="size-variant caption2--regular"
    >{{ trans('CART.SIZE') }}: {{ productSize }}</span
  >
</div>
