<div class="bulk-orders-info">
  <p class="bulk-orders-info__title heading2--bold">
    {{ trans('CART.BULK_ORDERS.MAIN_TITLE') }}
  </p>
  <p class="bulk-orders-info__sub-title body2--medium">
    {{ trans('CART.BULK_ORDERS.SUB_TITLE') }}
  </p>
  <button class="bulk-orders-info__video-button" (click)="openVideoDialog()">
    <img loading="lazy" src="{{ cartAssetsPath }}video.svg" />
    <p class="bulk-orders-info__video-button-text caption1--bold">
      {{ trans('CART.BULK_ORDERS.VIDEO_BUTTON_LABEL') }}
    </p>
  </button>
</div>
<loader
  size="md"
  *ngIf="isLoading; else showBulkOrdersActions"
  [loading]="isLoading"
  class="loader"
></loader>
<ng-template #showBulkOrdersActions>
  <div
    class="bulk-orders-info__buttons"
    *ngIf="!isLoading"
    [ngClass]="{
      'bulk-orders-info__buttons--disabled': disableActions
    }"
  >
    <button
      class="btn mat-raised-button bulk-orders-info__download-button caption1--bold"
      (click)="downloadTemplate()"
      [disabled]="disableActions"
      data-test-id="download-bulk-upload-sheet-btn"
    >
      <p class="bulk-orders-info__button-label caption1--bold">1</p>
      <img loading="lazy" src="{{ cartAssetsPath }}excel.svg" />
      {{
        trans(
          cartData?.products?.length > 0
            ? 'CART.BULK_ORDERS.DOWNLOAD_EXCEL_SHEET'
            : 'CART.BULK_ORDERS.DOWNLOAD_TEMPLATE'
        )
      }}
    </button>

    <label
      for="file-upload"
      class="bulk-orders-info__upload-button caption1--bold"
      [ngClass]="{
        'bulk-orders-info__upload-button--disabled': disableActions
      }"
    >
      <p class="bulk-orders-info__button-label caption1--bold">2</p>
      <img loading="lazy" src="{{ cartAssetsPath }}upload.svg" class="button-icon" />{{
        trans('CART.BULK_ORDERS.UPLOAD_EXCEL_SHEET')
      }}</label
    >
    <input
      type="file"
      id="file-upload"
      class="bulk-orders-info__file-upload-input"
      (change)="onUploadBulkOrdersFile($event)"
      accept=".xlsx"
      required
      data-test-id="upload-bulk-upload-sheet-btn"
    />
  </div>
</ng-template>
<div class="bulk-upload-error" *ngIf="errorMessages?.length > 0">
  <p class="bulk-upload-error__text body2--bold">
    {{ trans('CART.BULK_ORDERS.ERRORS_OCCURRED') }}
  </p>
  <button class="bulk-upload-error__button caption1--bold" (click)="onShowBulkOrderErrorsClicked()">
    {{ trans('CART.BULK_ORDERS.SHOW_ERRORS') }}
  </button>
</div>
