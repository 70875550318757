import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-confirm-delete-item',
  templateUrl: './confirm-delete-item.component.html',
  styleUrls: ['./confirm-delete-item.component.scss'],
  standalone: true,
  imports: [],
})
export class ConfirmDeleteItemComponent extends LocalizedComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  close(remove: boolean): void {
    this.dialogRef.close(remove);
  }
}
