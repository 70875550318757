import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { getMerchantIdVerification } from '@presentation/kyc/atoms/utils/get-verification-object';
import { toastError } from '@presentation/shared/toast';
import { InfoAlertComponent } from 'app/components/info-alert/info-alert.component';
import { IsFeatureEnabledUseCase } from 'app/core/usecases/market-availability/is-feature-enabled.usecase';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { finalize, lastValueFrom } from 'rxjs';
import { CartModel, CartProductModel } from 'src/app/core/domain/cart.model';
import { VariantModel } from 'src/app/core/domain/variant-group.model';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetUserCartUseCase } from 'src/app/core/usecases/cart/get-cart.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetMarketClosingReasonUseCase } from 'src/app/core/usecases/market-availability/get-closing-reason.usecase';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { LocalizedComponent } from '../base/localized.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { OrderComponent } from '../shared/components/order/order.component';
import { SelectedCountryTooltipComponent } from '../shared/components/selected-country-tooltip/selected-country-tooltip.component';
import { FEATURE_FLAGS, ORDER_STATUSES, PRODUCTS_V2_URL } from '../shared/constants';
import { QUANTITY_DISCOUNT } from '../shared/constants/feature-flags';
import { CheckoutProduct } from '../shared/interfaces/checkout.interface';
import { ResponsiveService } from '../shared/services/responsive.service';
import { cartModelToCheckoutProductMapper } from '../shared/utilities/cart-model-to-checkout-product.mapper';
import { cesLocationNotifierUtility } from '../shared/utilities/ces-location-notifier.utility';
import { featureAttributeAssign } from '../shared/utilities/feature-attribute-assign.utility';
import { BulkOrdersComponent } from './bulk-orders/bulk-orders.component';
import { CartBulkOrdersActionsComponent } from './cart-bulk-orders-actions/cart-bulk-orders-actions.component';
import { UploadedOrderLine } from './cart-bulk-orders-actions/cart-bulk-orders-interfaces';
import { CartProductsTableComponent } from './cart-products-table/cart-products-table.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { SuccessOrderModalComponent } from './success-order-modal/success-order-modal.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgClass,
    SelectedCountryTooltipComponent,
    CartProductsTableComponent,
    CartBulkOrdersActionsComponent,
    CartSummaryComponent,
    BulkOrdersComponent,
    OrderComponent,
    MatDialogModule,
    InfoAlertComponent,
  ],
})
export class CartComponent extends LocalizedComponent implements OnInit {
  public isLoading = false;

  public cartData: CartModel;

  public isEventCartPageLoadedSent = false;

  public shouldShowUploadedBulkOrders = false;

  public shouldShowCheckoutPage = false;

  public checkoutProducts: Array<CheckoutProduct>;

  public uploadedOrderLines: Array<UploadedOrderLine>;

  public uploadedVariantsHashMap: { [productId: string]: VariantModel } = {};

  private _isResizeImageEnabled: boolean;

  private _cesLocationNotifierUtility = cesLocationNotifierUtility();

  public shouldShowQuantityDiscount = false;

  public math = Math;

  public closingMessage: string;

  public isMarketClosed: boolean;

  public isDuplicateAccount = false;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _getUserCartUseCase: GetUserCartUseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _responsiveService: ResponsiveService,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _logGtagEventUseCase: LogGTagEventUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
    public _getMarketClosingReasonUseCase: GetMarketClosingReasonUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this._cesLocationNotifierUtility({ action: 'write', location: 'cart' });
    this._getCartData();
    this._checkResizeImagesEnabled();
    this._checkQuantityDiscountEnabled();
    this._getMarketClosure();
    this.checkMerchantIdVerification();
  }

  protected checkMerchantIdVerification(): void {
    getMerchantIdVerification(
      this._getFeatureFlagUseCase,
      this._checkUserFeatureExistsUseCase,
    ).then((response) => {
      this.isDuplicateAccount = response.isDuplicate;
    });
  }

  private _getMarketClosure(): void {
    this._getMarketClosingReasonUseCase.execute('orders').subscribe((res) => {
      if (res) {
        this.closingMessage = res;
      }
    });
    this._isFeatureEnabledUseCase.execute('orders').subscribe((res) => {
      this.isMarketClosed = !res;
    });
  }

  ngOnDestroy(): void {
    this._cesLocationNotifierUtility({ action: 'write', location: ' ' });
  }

  public onCartUpdated(): void {
    this._getCartData();
  }

  public showUploadedBulkOrders(uploadedOrderLines: any): void {
    this.uploadedOrderLines = uploadedOrderLines;
    this.shouldShowUploadedBulkOrders = true;
  }

  public orderSubmitted(event: { status: boolean; orderID: string; errorMessage: string }): void {
    if (event.status) {
      window.scrollTo(0, 0);
      const selectedProducts = this.cartData.products.filter(
        (product) => product.isAvailableToSeller,
      );
      this._logBulkOrdersToGtag(selectedProducts, event.orderID, user.id, user.phoneNumber);

      this.shouldShowCheckoutPage = false;
      this.shouldShowUploadedBulkOrders = false;

      this._openSuccessDialog(event.orderID);
    } else {
      const errorToDisplay =
        ORDER_STATUSES.ORDER_ERRORS.find((error) => event.errorMessage.includes(error.description))
          ?.translationKey || 'ERRORS.GENERIC_ERROR_MESSAGE';
      toastError(this.trans(errorToDisplay));
    }
  }

  public onQuantityChanged(): void {
    const {
      totalPrice,
      totalQuantityDiscount,
      totalProfit,
      totalVat,
      totalNetProfit,
      totalQuantity,
    } = this.cartData.products.reduce(
      (totals, product) => ({
        totalPrice: totals.totalPrice + product.finalPricePerItem * product.qty,
        totalQuantityDiscount:
          product.qty > 1
            ? totals.totalQuantityDiscount +
              ((product.sale ? product.sale.productPrice : product.originalPricePerItem) -
                product.appliedDiscount.price) *
                product.qty
            : totals.totalQuantityDiscount + 0,
        totalProfit: totals.totalProfit + product.finalProfitPerItem * product.qty,
        totalVat: totals.totalVat + product.vatPerItem * product.qty,
        totalNetProfit: totals.totalNetProfit + product.netProfitPerItem * product.qty,
        totalQuantity: totals.totalQuantity + product.qty,
      }),
      {
        totalPrice: 0,
        totalQuantityDiscount: 0,
        totalProfit: 0,
        totalVat: 0,
        totalNetProfit: 0,
        totalQuantity: 0,
      },
    );

    this.cartData = {
      ...this.cartData,
      totalPrice,
      totalQuantityDiscount,
      totalProfit,
      totalVat,
      totalNetProfit,
      totalQuantity,
    };
  }

  public onCheckout(): void {
    this.checkoutProducts = this.cartData.products.map((product) =>
      cartModelToCheckoutProductMapper(product, this._isResizeImageEnabled),
    );
    this.shouldShowCheckoutPage = true;
  }

  public onCloseCheckoutPage(): void {
    this.checkoutProducts = [];
    this.shouldShowCheckoutPage = false;
  }

  private async _logBulkOrdersToGtag(
    selectedProducts: Array<CartProductModel>,
    orderId: string,
    taagerId: number,
    phoneNumber: string,
  ) {
    const eventName = 'Cart_page_bulk_orders';
    const productsSKUMapping: {
      [sku: number]: {
        'Taager Selling Price': number;
        'Merchant Selling Price': number;
        'Merchant Profit': number;
        Quantity: number;
      };
    } = {};
    selectedProducts.forEach((selectedProduct: any) => {
      const quantity = selectedProduct.qty;
      productsSKUMapping[selectedProduct.prodId] = {
        'Taager Selling Price': selectedProduct.originalPricePerItem * quantity,
        'Merchant Selling Price': selectedProduct.finalPricePerItem * quantity,
        'Merchant Profit': selectedProduct.finalProfitPerItem * quantity,
        Quantity: quantity,
      };
    });
    const payload = {
      'Taager ID': taagerId,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'Phone Number': phoneNumber,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      'Merchant order number': orderId,
      SKUS: productsSKUMapping,
    };
    this._logGtagEventUseCase.execute({
      eventName,
      payload,
    });
  }

  private _getCartData(): void {
    this.isLoading = true;

    this._getUserCartUseCase
      .execute()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._trackCartPageLoaded();
        }),
      )
      .subscribe({
        next: (cartData) => {
          this.cartData = cartData;
          this.onQuantityChanged();
        },
        error: () => {
          toastError(this.trans('CART.TRY_AGAIN_LATER'));
          this.cartData = {
            totalPrice: 0,
            totalQuantityDiscount: 0,
            totalProfit: 0,
            totalVat: 0,
            totalNetProfit: 0,
            totalQuantity: 0,
            products: [],
          };
        },
      });
  }

  private _trackCartPageLoaded(): void {
    if (!this.isEventCartPageLoadedSent) {
      const cartItems = this.cartData.products.map((product) => product.prodId);

      this._logMixpanelEventUseCase.execute({
        eventName: 'cart_page_loaded',
        payload: {
          'Number of Products': this.cartData.products.length,
          'Total Products': this.cartData.totalQuantity,
          'Total Price': this.cartData.totalPrice,
          'Total Profit': this.cartData.totalProfit,
          'Product Ids': cartItems,
        },
      });

      this.isEventCartPageLoadedSent = true;
    }
  }

  private _checkResizeImagesEnabled(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.RESIZE_IMAGES).subscribe({
      next: (flag) => {
        if (flag) {
          this._getFeatureAttributeUsecase
            .execute(FEATURE_FLAGS.ALLOWED_IDS_FOR_RESIZE_IMAGES)
            .subscribe({
              next: (ids) => {
                ids = JSON.parse(ids) || [];
                this._isResizeImageEnabled = ids?.length === 0 || ids.includes(user.id);
              },
            });
        }
      },
    });
  }

  private _checkQuantityDiscountEnabled(): void {
    this._getFeatureAttributeUsecase.execute(QUANTITY_DISCOUNT).subscribe({
      next: (flag) => {
        this.shouldShowQuantityDiscount = featureAttributeAssign(flag, user.id, country.code);
      },
    });
  }

  private _openSuccessDialog(orderID: string): void {
    const dialogRef = this._dialog.open(SuccessOrderModalComponent, {
      width: '350px',
      data: orderID,
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this._router.navigate([PRODUCTS_V2_URL]);
        }
      },
    });
  }
}
