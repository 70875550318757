import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { getCurrentLocaleCode } from '@mongez/localization';
// @ts-ignore
import * as variantsResolver from '@taager-experience-shared/variants';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { CartProductModel } from 'src/app/core/domain/cart.model';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';

@Component({
  selector: 'app-cart-product-attributes',
  templateUrl: './cart-product-attributes.component.html',
  styleUrls: ['./cart-product-attributes.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle],
})
export class CartProductAttributesComponent extends LocalizedComponent implements OnInit {
  @Input() product: CartProductModel;

  public productColorHex: string;

  public productColorName: string;

  public productSize: string;

  ngOnInit(): void {
    this._getProductColor();
    this._getProductSize();
  }

  _getProductColor(): void {
    this.productColorHex = this.product.attributes.filter(
      (attribute) => attribute.type === 'color',
    )[0]?.value!;
    if (this.productColorHex) {
      this._getProductColorName(getCurrentLocaleCode());
    }
  }

  private _getProductColorName(currentLanguage: string): void {
    const isEnglishLanguage = currentLanguage === ENGLISH_LANGUAGE;
    const color = variantsResolver.getColorByHexCode(this.productColorHex);
    this.productColorName = isEnglishLanguage ? color.englishName : color.arabicName;
  }

  _getProductSize(): void {
    this.productSize = this.product.attributes.filter(
      (attribute) => attribute.type === 'size',
    )[0]?.value!;
  }
}
