import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { combineLatest } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CATEGORY_PAGE_ROUTE, FEATURE_FLAGS } from '../../shared/constants';
import { SORT_BY_INTRODUCED_AT } from '../../shared/constants/category-products';
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'app-second-product-discount-banner',
  templateUrl: './second-product-discount-banner.component.html',
  styleUrls: ['./second-product-discount-banner.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink],
})
export class SecondProductDiscountBannerComponent extends LocalizedComponent implements OnInit {
  @Input() discountAsSecondProductApplied: boolean;

  public shouldShowDiscountAsSecondProduct = false;

  public shouldShowDiscountAsSecondProductBanner = false;

  public discountedProductsListPath = {
    routerLink: CATEGORY_PAGE_ROUTE,
    queryParams: {
      onlyDiscountedAsSecondInPlacement: true,
    },
  };

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _productService: ProductService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._getFeatureFlags();
    this._fetchSecondProductList();
  }

  private _getFeatureFlags(): void {
    const shouldShowDiscountAsSecondProduct$ = this._getFeatureFlagUseCase.execute(
      FEATURE_FLAGS.DISCOUNTED_AS_SECOND_PRODUCT,
    );
    combineLatest([shouldShowDiscountAsSecondProduct$]).subscribe({
      next: ([shouldShowDiscountAsSecondProduct]) => {
        this.shouldShowDiscountAsSecondProduct = shouldShowDiscountAsSecondProduct;
      },
    });
  }

  private _fetchSecondProductList(): void {
    const filters = {
      onlyDiscountedAsSecondInPlacement: true,
      page: 1,
      pageSize: 1,
      sortBy: SORT_BY_INTRODUCED_AT,
    };
    this._productService.getProductsForCategory(filters).subscribe({
      next: (product) => {
        this.shouldShowDiscountAsSecondProductBanner = product.count > 0;
      },
    });
  }
}
