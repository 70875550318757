<div class="wrapper">
  <p data-test-id="order-place-success-message">
    {{ trans('CHECKOUT.ORDER_SUCCESS.REQUEST_COMPLETED') }}
  </p>
  <p>
    {{ trans('CHECKOUT.ORDER_SUCCESS.ORDER_NUMBER') }} :
    <span (click)="navigate()">{{ orderId }}</span>
  </p>
  <button (click)="close()" class="btn mat-flat-button">
    <i class="icon icon-tick tick-style"></i>
  </button>
</div>
