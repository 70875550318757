// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { CartProductModel } from 'src/app/core/domain/cart.model';
import { AttributeValueModel } from 'src/app/core/domain/variant-group.model';
import { AVAILABILITY_STATUSES } from '../constants/product-availability';
import { CheckoutProduct } from '../interfaces/checkout.interface';
import { getSizedImage, ImageSize } from './get-sized-image.utility';

const getAttributeValue = (productAttributes: Array<AttributeValueModel>, type: string) => {
  return productAttributes.filter((attribute) => attribute.type === type)[0]?.value;
};

export const cartModelToCheckoutProductMapper: (
  product: CartProductModel,
  isResizeImageEnabled: boolean,
) => CheckoutProduct = (product: CartProductModel, isResizeImageEnabled: boolean) => {
  const productColorHex = getAttributeValue(product.attributes, 'color');
  const productSize = getAttributeValue(product.attributes, 'size');
  return {
    ...product,
    productName: product.name,
    finalPrice: product.finalPricePerItem,
    finalProfit: product.finalProfitPerItem,
    originalPrice: product.originalPricePerItem,
    originalProfit: product.originalProfitPerItem,
    quantity: product.qty,
    productOriginalProfit: product.originalProfitPerItem,
    newPrice: product.finalPricePerItem,
    selected: product.availability !== AVAILABILITY_STATUSES.not_available,
    productColorHex,
    productColor: productColorHex && variants.getColorByHexCode(productColorHex).arabicName,
    productSize,
    ...(isResizeImageEnabled
      ? { image: getSizedImage(product.picture, ImageSize.small) }
      : { image: product.picture }),
  };
};
