<div class="wrapper">
  <p>
    {{
      data?.isBulkDelete
        ? trans('CART.DELETE_MULTIPLE_PRODUCT')
        : trans('CART.DELETE_SINGLE_PRODUCT')
    }}
  </p>
  <div class="d-flex justify-content-center action-buttons">
    <button (click)="close(false)" class="btn mat-flat-button">
      <i class="icon icon-close"></i>
    </button>
    <button
      (click)="close(true)"
      class="btn mat-flat-button confirm"
      data-test-id="confirm-delete-all-in-cart"
    >
      <i class="icon icon-tick"></i>
    </button>
  </div>
</div>
