import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-order-video',
  templateUrl: './bulk-order-video.component.html',
  styleUrls: ['./bulk-order-video.component.scss'],
  standalone: true,
})
export class BulkOrderVideoComponent {
  constructor(private _dialog: MatDialog) {}

  closeVideoDialog() {
    this._dialog.closeAll();
  }
}
