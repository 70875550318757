<div mat-dialog-content>
  <div class="info-container">
    <img [src]="assetsPath + 'discount-tag.svg'" alt="discount-tag" />
    <p class="title">{{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.DIALOG_TITLE') }}</p>
    <p class="details">{{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.DIALOG_DETAIL') }}</p>
  </div>
  <div class="btn-container">
    <button class="btn btn-close-dialog" (click)="closeDialog()">
      {{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.OKAY_BUTTON') }}
    </button>
  </div>
</div>
