<div class="dialog-title">
  <p class="heading2--bold">يرجى تصحيح الاخطاء التالية</p>
</div>
<table class="errors-table">
  <thead>
    <tr class="table-headers">
      <th class="table-header-cell body1--bold">الصف</th>
      <th class="table-header-cell body1--bold">الخطأ</th>
    </tr>
  </thead>
  <tbody>
    <tr class="table-row" *ngFor="let error of data.errorMessages">
      <td class="table-cell table-cell__row-number body1--bold">{{ error.row }}</td>
      <td class="table-cell table-cell__error body1--bold">
        {{ error.error }}
        <span class="table-cell__error-label body1--bold" *ngIf="error.label"
          >({{ error.label }})</span
        >
      </td>
    </tr>
  </tbody>
</table>
